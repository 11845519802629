.fixed-filter {
  width: 250px;
  height: 46px;
  margin-left: 10px;
}

.fixed-filter .basic-multi-select {
  width: 100%;
  height: 100%;
}

.fixed-filter .basic-multi-select__control {
  height: 100%;
}

.fixed-filter .basic-multi-select__value-container {
  max-height: 100%;
  overflow-y: auto;
}

.input-group.form-group.search-form {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input {
  flex: 1;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 50px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* Adjust height as needed */
  font-size: 16px; /* Adjust font size as needed */
  color: #333; /* Adjust color as needed */
}
